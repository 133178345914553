<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Stock, Phantom Stock & Deferred Cash Plan Design
      </h2>

      <img
        src="../../assets/img/stock_plan_banner.jpg"
        alt="Stock & Phantom Stock Plan Design Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          Are you effectively using share-based &/or deferred cash incentives to drive
          shareholder value creation? Are you satisfied with your organisation
          performance and executive/critical talent retention? There is a variety of
          vehicles to choose.
          Effective design coupled with appropriate performance measures can produce
          powerful compensation mechanisms that delight your shareholders,
          executives and employees alike!
        </p>
        <p class="py-2">
          Our assistance includes:
        </p>
        <p class="pb-4 text-red-600">
          Equity-settled / cash-settled share-based awards, including stock option
          (pre- or post-IPO), stock appreciation right, restricted stock or unit,
          performance share or unit and phantom stock plans
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
